<template>
  <DefaultButton
    :class="['zg-default-switch-button']"
    text
    icon
    :disabled="disabled"
    color="black"
    bgColor="white"
    @click.prevent="$emit('clicked')"
  >
    <v-icon small style="vertical-align: middle;" outline>{{ icon }}</v-icon>
  </DefaultButton>
</template>

<script>
export default {
  props: {
    right: Boolean,
    left: Boolean,
    disabled: Boolean,
    icon: {
      default: "fa fa-phone"
    }
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
  }
};
</script>

<style lang="scss" scoped>
.zg-default-switch-button {
  border-radius: 50% !important;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
  height: 30px !important;
  width: 30px !important;
}
</style>